import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import MjLogsTable from '../../components/MjLogsTable';

const Midjourney = () => (
  <>
    <MjLogsTable />
  </>
);

export default Midjourney;
